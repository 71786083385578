import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_DOWNTIME_CATEGORIES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import _ from "lodash";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-downtime-minor" }, [_c("a-card", { style: { height: "100%" } }, [_c("template", { slot: "title" }, [_c("h6", { staticClass: "h6 mb-0" }, [_vm._v("Downtime Reason (" + _vm._s(_vm.amountMinorItem) + ")")])]), _c("template", { slot: "extra" }, [_c("a-button", { attrs: { "type": "primary", "disabled": !_vm.recordItem || _vm.recordItem && _vm.recordItem.id === -1 || !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.addModal();
  } } }, [_vm._v("Add Downtime Reason")])], 1), _vm.tableRendered ? _c("list-composer", { attrs: { "has-advanced-search": false, "has-filter": false, "search-by": "Description", "ph-search-by": "Search by keywords", "custom-padding-table": "p-0 bg-white" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": record.isInactive ? "eye" : "eye-invisible", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.editModal(record);
    } } })], 1) : _vm._e()];
  } }], null, false, 3948915923) }, [_c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Name", "sorter": true } })], 1) : _vm._e()], 2), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_vm.visibleModal ? _c("a-modal", { attrs: { "title": _vm.modalObj.title, "centered": "", "ok-text": _vm.modalObj.okText }, on: { "ok": _vm.submitModal, "cancel": _vm.cancel }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submitModal);
      } } }, [_vm._v("OK")])];
    }, proxy: true }], null, true), model: { value: _vm.visibleModal, callback: function($$v) {
      _vm.visibleModal = $$v;
    }, expression: "visibleModal" } }, [_c("text-input", { key: "description", staticClass: "asterisk-required", attrs: { "label": "Name", "form-item": "", "rules": "required", "value": _vm.modalObj.record ? _vm.modalObj.record.description : "" }, on: { "change": function($event) {
      return _vm.storeValue("description", $event, _vm.modalObj.action);
    } } })], 1) : _vm._e()];
  } }]) })], 1);
};
var staticRenderFns$5 = [];
const __vue2_script$5 = {
  name: "ListDowntimeMinor",
  inject: ["apiUrl", "crud", "can"],
  props: {
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      tableRendered: true,
      recordItem: this.record,
      dataSource: [],
      amountMinorItem: 0,
      visibleModal: false,
      modalObj: {
        title: "",
        okText: "Create",
        value: "",
        action: ""
      }
    };
  },
  watch: {
    record(newVal) {
      this.recordItem = newVal;
      this.fetchList();
    }
  },
  created() {
    this.crud.setFilter("MajorID", {
      operator: "Eq",
      value: -1
    });
  },
  methods: {
    async fetchList() {
      this.tableRendered = false;
      this.crud.setFilter("MajorID", {
        operator: "Eq",
        value: this.recordItem.id ? this.recordItem.id : -1
      });
      await this.crud.fetchList();
      this.amountMinorItem = this.crud.getList().length;
      this.tableRendered = true;
    },
    storeValue(resourceKey, value, action) {
      this.crud.updateEntity(action, { resourceKey, value });
    },
    addModal() {
      this.modalObj = {
        record: null,
        title: "Add Downtime Reason",
        okText: "Create",
        action: "createForm"
      };
      this.visibleModal = true;
      this.storeValue("majorID", this.recordItem.id, this.modalObj.action);
      this.storeValue("areaID", this.recordItem.areaID, this.modalObj.action);
    },
    editModal(record) {
      this.modalObj = {
        record,
        title: "Edit Downtime Reason",
        okText: "Edit",
        action: "editForm"
      };
      this.storeValue("code", this.modalObj.record.code, this.modalObj.action);
      this.storeValue("description", this.modalObj.record.description, this.modalObj.action);
      this.visibleModal = true;
    },
    async submitModal() {
      let isCreated = this.modalObj.action === "createForm";
      let customUrl = this.modalObj.record ? `${this.apiUrl}/line-performance/downtime-minors/${this.modalObj.record.id}` : `${this.apiUrl}/line-performance/downtime-minors/`;
      await this.crud.submitEntity(isCreated ? "create" : "replace", customUrl);
      this.visibleModal = false;
      if (isCreated) {
        this.tableRendered = false;
        this.fetchList();
      }
    },
    cancel() {
      this.visibleModal = false;
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, null, null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListDowntimeMinor$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-downtime-minor__container" }, [_c("resource", { attrs: { "name": "line-performance.downtime-minors", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-downtime-minor", { attrs: { "record": _vm.recordItem }, on: { "update:record": function($event) {
    _vm.recordItem = $event;
  } } })], 1), _c("router-view")], 1);
};
var staticRenderFns$4 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    ListDowntimeMinor: ListDowntimeMinor$1
  },
  props: {
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      page: LINE_PERFORMANCE_DOWNTIME_CATEGORIES,
      apiUrl: apiUrl$2,
      recordItem: this.record
    };
  },
  watch: {
    record(newVal) {
      this.recordItem = newVal;
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListDowntimeMinor = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-downtime-groups" }, [_c("a-card", { style: { height: "100%" } }, [_c("template", { slot: "title" }, [_c("h6", { staticClass: "h6 mb-0" }, [_vm._v("Downtime Groups (" + _vm._s(_vm.groupCount) + ")")])]), _vm.can(_vm.permissions.maintain) ? _c("template", { slot: "extra" }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
    return _vm.addGroup();
  } } }, [_vm._v("Add Downtime Group")])], 1) : _vm._e(), _c("list-composer", { attrs: { "has-advanced-search": false, "has-filter": false, "search-by": "Description", "ph-search-by": "Search by keywords", "custom-padding-table": "p-0 mb-4 bg-white" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": record.isInactive ? "eye" : "eye-invisible", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.editModal(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Name", "sorter": true } })], 1)], 2), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_vm.visibleModal ? _c("a-modal", { attrs: { "title": _vm.modalObj.title, "centered": "", "ok-text": _vm.modalObj.okText }, on: { "ok": function($event) {
      return handleSubmit(_vm.submitModal);
    } }, model: { value: _vm.visibleModal, callback: function($$v) {
      _vm.visibleModal = $$v;
    }, expression: "visibleModal" } }, [_c("text-input", { key: "description", staticClass: "asterisk-required", attrs: { "label": "Name", "form-item": "", "rules": "required", "value": _vm.modalObj.record ? _vm.modalObj.record.description : "" }, on: { "change": function($event) {
      return _vm.storeValue("description", $event, _vm.modalObj.action);
    } } })], 1) : _vm._e()];
  } }]) })], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "ListDowntimeGroups",
  inject: ["apiUrl", "crud", "area", "can"],
  props: {
    areaId: {
      type: [Number, String],
      default: -1
    }
  },
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      visibleModal: false,
      modalObj: {
        title: "",
        okText: "Create",
        value: "",
        action: ""
      },
      areaValue: { operator: "Eq", value: "" }
    };
  },
  computed: {
    groupCount() {
      const { count } = this.crud.getPagination();
      return count;
    },
    filterFacility() {
      return this.$store.getters["identities.common.facilities/list"] || [];
    }
  },
  watch: {
    areaId(newVal) {
      this.crud.setFilter("AreaID", {
        operator: "Eq",
        value: typeof newVal == "number" ? newVal : -1
      });
      this.areaValue.value = newVal;
      this.crud.fetchList();
    }
  },
  async created() {
    if (this.filterFacility.length > 0) {
      this.areaValue.value = this.area.value;
    }
    this.crud.setFilter("AreaID", {
      operator: "Eq",
      value: this.areaValue.value ? this.areaValue.value : -1
    });
    this.crud.fetchList();
  },
  methods: {
    storeValue(resourceKey, value, action) {
      this.crud.updateEntity(action, { resourceKey, value });
    },
    addGroup() {
      this.modalObj = {
        id: "",
        title: "Add Downtime Group",
        okText: "Create",
        action: "createForm"
      };
      this.visibleModal = true;
    },
    editModal(record) {
      this.modalObj = {
        record,
        title: "Edit Downtime Group",
        okText: "Edit",
        action: "editForm"
      };
      this.storeValue("code", this.modalObj.record.code, this.modalObj.action);
      this.storeValue("description", this.modalObj.record.description, this.modalObj.action);
      this.visibleModal = true;
    },
    async submitModal() {
      let isCreated = this.modalObj.action === "createForm";
      this.storeValue("areaID", this.areaValue.value, this.modalObj.action);
      let customUrl = this.modalObj.record ? `${this.apiUrl}/line-performance/downtimegroups/${this.modalObj.record.id}` : `${this.apiUrl}/line-performance/downtimegroups/`;
      await this.crud.submitEntity(isCreated ? "create" : "replace", customUrl);
      this.visibleModal = false;
      if (isCreated) {
        this.tableRendered = false;
        this.fetchList();
      }
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListDowntimeGroups$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-downtime-categories" }, [_c("resource", { attrs: { "name": "line-performance.downtimegroups", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-downtime-groups", { attrs: { "area-id": _vm.areaId } })], 1), _c("router-view")], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  name: "DowntimeCategories",
  components: { ListDowntimeGroups: ListDowntimeGroups$1 },
  props: {
    areaId: {
      type: [Number, String],
      default: -1
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      page: LINE_PERFORMANCE_DOWNTIME_CATEGORIES
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListDowntimeGroups = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "lp-downtime" }, [_vm._m(0), _c("div", { staticClass: "body mx-5 my-5" }, [_c("div", { staticClass: "d-flex align-items-stretch downtime-table-container pt-3" }, [_c("div", { staticClass: "downtime-table" }, [_c("a-card", { style: { height: "100%" } }, [_c("template", { slot: "title" }, [_c("h6", { staticClass: "h6 mb-0" }, [_vm._v("Downtime Categories (" + _vm._s(_vm.amountMajorItem) + ")")])]), _c("template", { slot: "extra" }, [_c("a-button", { attrs: { "type": "primary", "disabled": _vm.areaValue.value === "" || _vm.areaValue.value === -1 || !_vm.can(_vm.permissions.maintain) }, on: { "click": function($event) {
    return _vm.addGroup();
  } } }, [_vm._v("Add Downtime Category")])], 1), _vm.tableRendered ? _c("list-composer", { attrs: { "has-advanced-search": false, "has-filter": false, "selected-row-keys": _vm.selectedRowKeys, "search-by": "Description", "ph-search-by": "Search by keywords", "custom-padding-table": "p-0 bg-white" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": record.isInactive ? "eye" : "eye-invisible", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.editModal(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.loadDTMinor(record);
    } } })], 1)];
  } }], null, false, 1701754462) }, [_c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Name", "sorter": true } })], 1) : _vm._e()], 2)], 1), _c("div", { staticClass: "downtime-table" }, [_c("list-downtime-minor", { attrs: { "record": _vm.recordItem }, on: { "update:record": function($event) {
    _vm.recordItem = $event;
  } } })], 1)]), _c("div", { staticClass: "downtime-groups my-3" }, [_c("list-downtime-groups", { attrs: { "area-id": _vm.areaValue.value } })], 1)]), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_vm.visibleModal ? _c("a-modal", { attrs: { "title": _vm.modalObj.title, "centered": "", "ok-text": _vm.modalObj.okText }, on: { "ok": function($event) {
      return handleSubmit(_vm.submitModal);
    } }, model: { value: _vm.visibleModal, callback: function($$v) {
      _vm.visibleModal = $$v;
    }, expression: "visibleModal" } }, [_c("a-row", { attrs: { "gutter": 32 } }, [_c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { key: "description", staticClass: "asterisk-required", attrs: { "label": "Name", "form-item": "", "rules": "required", "value": _vm.modalObj.record ? _vm.modalObj.record.description : "" }, on: { "change": function($event) {
      return _vm.storeValue("description", $event, _vm.modalObj.action);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("switch-input", { attrs: { "label": "Allow Frequency", "value": _vm.modalObj.record ? _vm.modalObj.record.allowFrequency : false }, on: { "change": function($event) {
      return _vm.storeValue("allowFrequency", $event, _vm.modalObj.action);
    } } })], 1)], 1)], 1) : _vm._e()];
  } }]) })], 1);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "header px-5 pt-2 pb-4 mb-0 d-flex justify-content-between align-items-center" }, [_c("h5", { staticClass: "pr-3 m-0 h5" }, [_vm._v("Downtime Categories")])]);
}];
var ListDowntimeCategories_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".lp-downtime .header[data-v-4a841623]{box-shadow:inset 0 -1px #eaedf0;background-color:#fff}.lp-downtime .header h5[data-v-4a841623]{font-weight:700!important;line-height:32px}.lp-downtime[data-v-4a841623] .custom-filter .select-input{width:160px}.lp-downtime[data-v-4a841623] .filter-container,.lp-downtime[data-v-4a841623] .keyword-filter{width:100%!important}.lp-downtime[data-v-4a841623] .ant-card-body{padding-bottom:0}.lp-downtime[data-v-4a841623] .ant-card-body .ant-table-body .ant-table-selection-column{padding:0;border:0px}.lp-downtime[data-v-4a841623] .ant-card-body .ant-table-body .ant-table-selection-column>span{display:none}.lp-downtime[data-v-4a841623] .ant-card-body .ant-table-body .ant-table-column-has-actions{width:100%}.lp-downtime[data-v-4a841623] .ant-card-body .ant-table-body .ant-table-row-cell-break-word{white-space:nowrap}.lp-downtime .downtime-table-container[data-v-4a841623]{margin:0 -12px}.lp-downtime .downtime-table-container .downtime-table[data-v-4a841623]{padding:0 12px;width:50%}.lp-downtime .downtime-table-container .downtime-table .list-downtime-minor__container[data-v-4a841623]{height:100%}.lp-downtime .downtime-table-container .downtime-table .list-downtime-minor__container[data-v-4a841623]>div,.lp-downtime .downtime-table-container .downtime-table .list-downtime-minor__container[data-v-4a841623]>div>.list-downtime-minor{height:100%}.lp-downtime .downtime-table-container .downtime-table[data-v-4a841623] .ant-table-row-selected>td{background:#e5efff!important}\n")();
const __vue2_script$1 = {
  name: "ListDowntimeCategories",
  inject: ["apiUrl", "crud", "resourceName", "facility", "area", "can"],
  components: {
    ListDowntimeMinor,
    ListDowntimeGroups
  },
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      tableRendered: false,
      areaValue: { operator: "Eq", value: "" },
      recordItem: null,
      areaItemID: -1,
      selectedRowKeys: [],
      visibleModal: false,
      modalObj: {
        title: "",
        okText: "Create",
        value: "",
        action: ""
      }
    };
  },
  computed: {
    filterFacility() {
      return this.$store.getters["identities.common.facilities/list"] || [];
    },
    listArea() {
      return this.$store.getters["identities.common.departments/list"] || [];
    },
    amountMajorItem() {
      const { count } = this.crud.getPagination();
      return count;
    }
  },
  async created() {
    this.tableRendered = false;
    if (this.filterFacility.length > 0) {
      this.areaValue.value = this.area.value;
    }
    this.crud.setFilter("AreaID", this.area);
    this.fetchList(true);
  },
  methods: {
    async fetchList(isCreated) {
      const { count, pageSize, page } = this.crud.getPagination();
      this.crud.setPagination({ count, page: isCreated ? 1 : page, pageSize });
      if (!isCreated)
        await this.crud.fetchList();
      this.tableRendered = true;
    },
    storeValue(resourceKey, value, action) {
      this.crud.updateEntity(action, { resourceKey, value });
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: err.response.data.message || "Errors"
      });
    },
    loadDTMinor(record) {
      if (_.isEmpty(record))
        record.id = -1;
      else {
        Object.assign(record, { areaID: this.areaValue.value });
      }
      this.selectedRowKeys = [record.id];
      this.recordItem = record;
    },
    addGroup() {
      this.modalObj = {
        id: "",
        title: "Add Downtime Category",
        okText: "Create",
        action: "createForm"
      };
      this.visibleModal = true;
    },
    editModal(record) {
      this.modalObj = {
        record,
        title: "Edit Downtime Category",
        okText: "Edit",
        action: "editForm"
      };
      this.storeValue("code", this.modalObj.record.code, this.modalObj.action);
      this.storeValue("description", this.modalObj.record.description, this.modalObj.action);
      this.storeValue("allowFrequency", this.modalObj.record.allowFrequency, this.modalObj.action);
      this.visibleModal = true;
    },
    async submitModal() {
      let isCreated = this.modalObj.action === "createForm";
      this.storeValue("areaID", this.areaValue.value, this.modalObj.action);
      let customUrl = this.modalObj.record ? `${this.apiUrl}/line-performance/downtime-majors/${this.modalObj.record.id}` : `${this.apiUrl}/line-performance/downtime-majors/`;
      await this.crud.submitEntity(isCreated ? "create" : "replace", customUrl);
      this.visibleModal = false;
      if (isCreated) {
        this.tableRendered = false;
        this.fetchList();
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "4a841623", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListDowntimeCategories = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-downtime-categories" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "line-performance.downtime-majors", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-downtime-categories")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "DowntimeCategories",
  components: { ListDowntimeCategories },
  data() {
    return {
      page: LINE_PERFORMANCE_DOWNTIME_CATEGORIES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "downtime-categories",
          title: "Downtime Categories",
          path: "/line-performance/downtime-categories"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
